import axios from "axios";
import { Session } from "../../components/NavRoute/NavRoute";
import { Atendimento } from "../../models/atendimentoResponse";
import { CreateMemorialResponse } from "../../models/createMemorialResponse";
import { MemorialRequest } from "../../models/memorialRequest";
const api = axios.create({
  baseURL: process.env.REACT_APP_SERVER_URL,
});

export const getAtendimentos = async (id: number) => {
  const session = sessionStorage.getItem("iv-crm");
  let token = "";
  if (session !== null) {
    const sessionBody: Session = JSON.parse(session);
    token = sessionBody.accessToken;
  }
  try {
    const response = await api.get(`/api/memorial?id=${id}`, {
      headers: {
        "x-access-token": token,
      },
    });
    const dataResponse: Atendimento = response.data;
    return { res: true, data: dataResponse };
  } catch (error) {
    return { res: false, errorData: "" };
  }
};

export const getMemorial = async (id: number) => {
  const session = sessionStorage.getItem("iv-crm");
  let token = "";
  if (session !== null) {
    const sessionBody: Session = JSON.parse(session);
    token = sessionBody.accessToken;
  }
  try {
    const response = await api.get(`/api/memorial/${id}`, {
      headers: {
        "x-access-token": token,
      },
    });
    const dataResponse: CreateMemorialResponse = response.data;
    return { res: true, data: dataResponse };
  } catch (error) {
    return { res: false, errorData: "" };
  }
};

interface getTokenRequest {
  res: boolean;
  data: {
    message: string;
    token: string;
    res: boolean;
  };
}

export const getToken = async (): Promise<getTokenRequest> => {
  const session = sessionStorage.getItem("iv-crm");
  let token = "";
  if (session !== null) {
    const sessionBody: Session = JSON.parse(session);
    token = sessionBody.accessToken;
  }
  try {
    const response = await api.get("/api/memorial/token", {
      headers: {
        "x-access-token": token,
      },
    });
    return { res: true, data: response.data };
  } catch (error) {
    return { res: false, data: { message: "", token: "", res: false } };
  }
};

export const editMemorial = async (data: MemorialRequest) => {
  const session = sessionStorage.getItem("iv-crm");
  let token = "";
  if (session !== null) {
    const sessionBody: Session = JSON.parse(session);
    token = sessionBody.accessToken;
  }
  try {
    const response = await api.put("/api/memorial", data, {
      headers: {
        "x-access-token": token,
      },
    });
    const dataResponse: CreateMemorialResponse = response.data;
    return { res: true, data: dataResponse };
  } catch (error) {
    return { res: false, errorData: "" };
  }
};

export const editAtendimento = async (data: MemorialRequest) => {
  const session = sessionStorage.getItem("iv-crm");
  let token = "";
  if (session !== null) {
    const sessionBody: Session = JSON.parse(session);
    token = sessionBody.accessToken;
  }
  try {
    const response = await api.put("/api/atendimento", data, {
      headers: {
        "x-access-token": token,
      },
    });
    const dataResponse: CreateMemorialResponse = response.data;
    return { res: true, data: dataResponse };
  } catch (error) {
    return { res: false, errorData: "" };
  }
};

export function extractDate(datetime: string): string {
  const date = new Date(datetime); // Converte a string para um objeto Date
  const day = String(date.getUTCDate()).padStart(2, "0");
  const month = String(date.getUTCMonth() + 1).padStart(2, "0"); // Mês é zero-based
  const year = date.getUTCFullYear();
  return `${day}/${month}/${year}`;
}

export function extractTime(datetime: string): string {
  const date = new Date(datetime); // Converte a string para um objeto Date
  const hours = String(date.getUTCHours()).padStart(2, "0");
  const minutes = String(date.getUTCMinutes()).padStart(2, "0");
  return `${hours}:${minutes}`;
}

export function formatToRecifeTimezone(
  dateText: string,
  timeText: string
): string {
  // Converte a data do formato DD/MM/YYYY para YYYY-MM-DD
  const [day, month, year] = dateText.split("/");
  const formattedDate = `${year}-${month}-${day}`;

  // Combina a data formatada e a hora
  const combinedText = `${formattedDate}T${timeText}:00`;

  // Cria um objeto Date baseado na entrada
  const inputDate = new Date(combinedText);

  // Verifica se a data é válida
  if (isNaN(inputDate.getTime())) {
    throw new Error("Data ou hora inválida");
  }

  // Ajusta o horário para subtrair uma hora
  inputDate.setHours(inputDate.getHours() - 1);

  // Converte a data para o fuso horário de Recife
  const options: Intl.DateTimeFormatOptions = {
    timeZone: "America/Recife",
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    hour12: false,
  };

  // Formata a data no estilo "yyyy-MM-dd HH:mm:ss"
  const formatter = new Intl.DateTimeFormat("pt-BR", options);
  const parts = formatter.formatToParts(inputDate);

  // Extração dos componentes formatados
  const yearPart = parts.find((part) => part.type === "year")?.value;
  const monthPart = parts.find((part) => part.type === "month")?.value;
  const dayPart = parts.find((part) => part.type === "day")?.value;
  const hourPart = parts.find((part) => part.type === "hour")?.value;
  const minutePart = parts.find((part) => part.type === "minute")?.value;
  const secondPart = parts.find((part) => part.type === "second")?.value;

  // Retorna o texto final
  return `${yearPart}-${monthPart}-${dayPart} ${hourPart}:${minutePart}:${secondPart}`;
}
