import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import {
  LinearProgress,
  FormControl,
  Divider,
  FormLabel,
  RadioGroup,
  Radio,
} from "@material-ui/core";
import PeopleAltIcon from "@material-ui/icons/PeopleAlt";
import SnackBar from "../../components/Snackbar/SnackBar";
import { createAtendimento, createMemorial, getToken } from "./services";
import CPFMask from "../../components/Masks/CPFMask";
import DateMask from "../../components/Masks/DateMask";
import HourMask from "../../components/Masks/HourMask";
import { CustomModal } from "../../components/Modal/CustomModal";
import { CreateMemorialResponse } from "../../models/createMemorialResponse";
import { Alert } from "@material-ui/lab";
import { AtendimentoRequest } from "../../models/createAtendimentoRequest";
import { MemorialRequest } from "../../models/memorialRequest";
import { isValidCPF } from "../../utils";
import { formatToRecifeTimezone } from "../EditMemorial/services";

const useHelperTextStyles = makeStyles(() => ({
  root: {
    marginLeft: 0,
  },
}));

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(16),
    marginBottom: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(8, 0, 2),
  },
  formControl: {
    width: "100%",
  },
  helperText: {
    marginLeft: 0,
  },
  progress: {
    marginTop: "1em",
  },
  divider: {
    margin: "1em 0",
  },
  alert: {
    marginBottom: "1em",
  },
}));

export default function Home() {
  const classes = useStyles();
  const helperTextStyles = useHelperTextStyles();

  const [token, setToken] = useState<string>("");
  const [lateName, setLateName] = useState<string>("");
  const [lateCPF, setLateCPF] = useState<string>("");
  const [lateBirthday, setLateBirthday] = useState<string>("");
  const [lateDeathday, setLateDeathday] = useState<string>("");
  const [notes, setNotes] = useState<string>("");

  const [relativeName, setRelativeName] = useState<string>("");
  const [relativeEmail, setRelativeEmail] = useState<string>("");
  const [passwordError, setPasswordError] = useState<string | undefined>("");
  const [confirmPasswordError, setConfirmPasswordError] = useState<
    string | undefined
  >("");

  const [funeralLocation, setFuneralLocation] = useState<string>("");
  const [funeralCity, setFuneralCity] = useState<string>("");
  const [funeralState, setFuneralState] = useState<string>("");
  const [funeralStart, setFuneralStart] = useState<string>("");
  const [funeralEnd, setFuneralEnd] = useState<string>("");
  const [funeralStartTime, setFuneralStartTime] = useState<string>("");
  const [funeralEndTime, setFuneralEndTime] = useState<string>("");

  const [burialLocation, setBurialLocation] = useState<string>("");
  const [burialCity, setBurialCity] = useState<string>("");
  const [burialState, setBurialState] = useState<string>("");
  const [burialStart, setBurialStart] = useState<string>("");
  const [burialEnd, setBurialEnd] = useState<string>("");
  const [burialStartTime, setBurialStartTime] = useState<string>("");
  const [burialEndTime, setBurialEndTime] = useState<string>("");

  const [has_burial_time, setHas_burial_time] = useState<number>(0);
  const [has_funeral_time, setHas_funeral_time] = useState<number>(0);

  const [openDetailModal, setOpenDetailModal] = useState(false);

  const [isTribute, setIsTribute] = useState<string>("s");
  const [isCremation, setIsCremation] = useState<string>("n");

  const [isRequestLoading, setIsRequestLoading] = useState<boolean>(false);
  const [memorialData, setMemorialData] = useState<CreateMemorialResponse>();

  const [password, setPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");

  const [isSnackOpen, setSnackOpen] = useState<boolean>(false);
  const [snackMessage, setSnackMessage] = useState<string>("");
  const [isSnackError, setSnackError] = useState<boolean>(false);

  const [relativeNameError, setRelativeNameError] = useState<
    string | undefined
  >("");
  const [relativeEmailError, setRelativeEmailError] = useState<
    string | undefined
  >("");

  const [lateNameError, setLateNameError] = useState<string | undefined>("");
  const [lateBirthdayError, setLateBirthdayError] = useState<
    string | undefined
  >("");
  const [lateDeathdayError, setLateDeathdayError] = useState<
    string | undefined
  >("");
  const [lateCPFError, setLateCPFError] = useState<string | undefined>("");

  const handleChangeRelativeName = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    setRelativeName(event.target.value as string);
    setRelativeNameError("");
  };

  const handleChangeRelativeEmail = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    setRelativeEmail(event.target.value as string);
    setRelativeEmailError("");
  };

  const handleChangeLateName = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    setLateName(event.target.value as string);
    setLateNameError("");
  };

  const handleChangeLateCPF = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    setLateCPF(event.target.value as string);
    setLateCPFError("");
  };

  const handleChangeLateBirthday = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    setLateBirthday(event.target.value as string);
    setLateBirthdayError("");
  };

  const handleChangeLateDeathday = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    setLateDeathday(event.target.value as string);
    setLateDeathdayError("");
  };

  const handleChangeFuneralLocation = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    setFuneralLocation(event.target.value as string);
  };

  const handleChangeFuneralState = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    setFuneralState(event.target.value as string);
  };

  const handleChangeFuneralCity = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    setFuneralCity(event.target.value as string);
  };

  const handleChangeFuneralStart = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    setFuneralStart(event.target.value as string);
  };

  const handleChangeFuneralEnd = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    setFuneralEnd(event.target.value as string);
  };

  const handleChangeFuneralStartTime = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    setFuneralStartTime(event.target.value as string);
  };

  const handleChangeFuneralEndTime = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    setFuneralEndTime(event.target.value as string);
  };

  const handleChangeBurialLocation = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    setBurialLocation(event.target.value as string);
  };

  const handleChangeBurialState = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    setBurialState(event.target.value as string);
  };

  const handleChangeBurialCity = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    setBurialCity(event.target.value as string);
  };

  const handleChangeBurialStart = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    setBurialStart(event.target.value as string);
  };

  const handleChangeBurialEnd = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    setBurialEnd(event.target.value as string);
  };

  const handleChangeBurialStartTime = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    setBurialStartTime(event.target.value as string);
  };

  const handleChangeBurialEndTime = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    setBurialEndTime(event.target.value as string);
  };

  const handleChangeIsTribute = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setIsTribute(event.target.value);
  };

  const handleChangeIsCremmation = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setIsCremation(event.target.value);
  };

  const handleChangeNotes = (event: React.ChangeEvent<{ value: unknown }>) => {
    setNotes(event.target.value as string);
  };

  const handleChangePassword = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    setPassword(event.target.value as string);
    setPasswordError("");
  };

  const handleChangeConfirmPassword = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    setConfirmPassword(event.target.value as string);
    setConfirmPasswordError("");
  };

  const handleChangeBurialToBeDefined = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setHas_burial_time(has_burial_time === 1 ? 0 : 1);
    if (event.target.value.includes("0")) {
      setBurialStart("");
      setBurialEnd("");
      setBurialStartTime("");
      setBurialEndTime("");
    }
  };

  const handleChangeFuneralToBeDefined = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setHas_funeral_time(has_funeral_time === 1 ? 0 : 1);
    if (event.target.value.includes("0")) {
      setFuneralStart("");
      setFuneralEnd("");
      setFuneralStartTime("");
      setFuneralEndTime("");
    }
  };

  const sendGetTokenRequest = async () => {
    let getTokenRequest = await getToken();
    if (getTokenRequest.res && getTokenRequest.data.token !== undefined) {
      setToken("Bearer " + getTokenRequest.data.token);
    }
  };

  const formatDate = (dateString: string) => {
    var dateComponents = dateString.split("/");
    var day = parseInt(dateComponents[0]);
    var month = parseInt(dateComponents[1]);
    var year = parseInt(dateComponents[2]);

    const dateFormatted = new Date(year, month - 1, day)
      .toISOString()
      .replace("T", " ")
      .replace("Z", "");
    return dateFormatted;
  };

  const sendCreateAtendimentoRequest = async () => {
    setIsRequestLoading(true);

    const data: AtendimentoRequest = {
      falecidomemorialativo: 1,
      falecidocpf: lateCPF,
      falecidonome: lateName,
      falecidodatanascimento: formatDate(lateBirthday),
      falecidodataobito: formatDate(lateDeathday),
      memorialresponsavelnome: relativeName,
      memorialresponsavelemail: relativeEmail,
    };

    createAtendimento(data).then((response) => {
      if (response.data !== undefined && response.res) {
        sendCreateMemorialRequest(response.data.falecidoid);
      } else {
        setSnackError(true);
        setSnackOpen(true);
        setSnackMessage("Erro ao cadastrar memorial. Tente novamente.");
        setIsRequestLoading(false);
      }
    });
  };

  const sendCreateMemorialRequest = async (falecidoApiId: number) => {
    sendGetTokenRequest().then(async () => {
      const data: MemorialRequest = {
        token: token,
        data: {
          falecidoId: falecidoApiId,
          cpf: lateCPF,
          name: lateName,
          description: notes,
          birthdayDateText: formatDate(lateBirthday),
          deathDateText: formatDate(lateDeathday),
          funeralLocation: funeralLocation,
          funeralCity: funeralCity,
          funeralState: funeralState,
          funeralStartDateText:
            funeralStart !== "" || funeralStartTime !== ""
              ? formatToRecifeTimezone(funeralStart, funeralStartTime)
              : "",
          funeralEndDateText:
            funeralEnd !== "" || funeralEndTime !== ""
              ? formatToRecifeTimezone(funeralEnd, funeralEndTime)
              : "",
          burialLocation: burialLocation,
          burialCity: burialCity,
          burialState: burialState,
          burialStartDateText:
            burialStart !== "" || burialStartTime !== ""
              ? formatToRecifeTimezone(burialStart, burialStartTime)
              : "",
          burialEndDateText:
            burialEnd !== "" || burialEndTime !== ""
              ? formatToRecifeTimezone(burialEnd, burialEndTime)
              : "",
          activeTribute: isTribute === "s" ? true : false,
          cremation: isCremation === "s" ? true : false,
          hasBurialTime: has_burial_time,
          hasFuneralTime: has_funeral_time,
          relative: {
            name: relativeName,
            email: relativeEmail,
            password: password,
          },
        },
      };

      let createMemorialRequest = await createMemorial(data);

      if (createMemorialRequest.data !== undefined) {
        setMemorialData(createMemorialRequest.data);
      }

      if (
        createMemorialRequest.res &&
        createMemorialRequest.data !== undefined
      ) {
        setSnackError(false);
        setSnackOpen(true);
        cleanAllFields();
        setSnackMessage("Sucesso.");
        setOpenDetailModal(true);
      } else {
        setSnackError(true);
        setSnackOpen(true);
        setSnackMessage("Erro ao cadastrar memorial. Tente novamente.");
      }
      setIsRequestLoading(false);
    });
  };

  const validateForm = () => {
    const isCpfValid: boolean = isValidCPF(lateCPF);
    if (!isCpfValid && lateCPF !== "") {
      setLateCPFError("CPF inválido");
      setSnackError(true);
      setSnackMessage("CPF inválido.");
      setSnackOpen(true);
    }
    if (relativeEmail === "") {
      setRelativeEmailError("Preencha o email do responsável");
    }
    if (relativeName === "") {
      setRelativeNameError("Preencha o nome do responsável");
    }
    if (password === "") {
      setPasswordError("Preencha a senha");
    }
    if (confirmPassword === "") {
      setConfirmPasswordError("Confirme a senha");
    }
    if (lateName === "") {
      setLateNameError("Preencha o nome");
    }
    if (lateBirthday === "") {
      setLateBirthdayError("Preencha a data de nascimento");
    }
    if (lateDeathday === "") {
      setLateDeathdayError("Preencha a data de falecimento");
    }
    if (lateCPF === "") {
      setLateCPFError("Preencha o CPF");
    }
    if (
      relativeEmail !== "" &&
      relativeName !== "" &&
      password !== "" &&
      confirmPassword !== "" &&
      lateName !== "" &&
      lateBirthday !== "" &&
      lateBirthday !== "" &&
      lateDeathday !== "" &&
      lateCPF !== "" &&
      isCpfValid
    ) {
      sendCreateAtendimentoRequest();
    }
  };
  const cleanAllFields = () => {
    setRelativeName("");
    setRelativeEmail("");
    setPassword("");
    setConfirmPassword("");

    setLateName("");
    setLateBirthday("");
    setLateDeathday("");
    setLateCPF("");

    setBurialLocation("");
    setBurialState("");
    setBurialCity("");
    setBurialStart("");
    setBurialEnd("");
    setBurialStartTime("");
    setBurialEndTime("");

    setFuneralLocation("");
    setFuneralState("");
    setFuneralCity("");
    setFuneralStart("");
    setFuneralEnd("");
    setFuneralStartTime("");
    setFuneralEndTime("");

    setNotes("");
  };

  useEffect(() => {
    if (confirmPassword !== password) {
      setConfirmPasswordError("A senha deve ser igual");
    } else {
      setConfirmPasswordError("");
    }
  }, [confirmPassword, password]);

  useEffect(() => {
    sendGetTokenRequest();
  }, []);

  return (
    <Container component="main" maxWidth="md">
      <CssBaseline />
      <div className={classes.paper}>
        <PeopleAltIcon />
        <Typography component="h1" variant="h5">
          Cadastro de Atendimento
        </Typography>
        <CustomModal
          dialogTitle="O Memorial foi criado com sucesso"
          openDialog={openDetailModal}
          setOpenDialog={setOpenDetailModal}
          primaryButtonText="Fechar"
          onClickPrimaryButton={() => {
            setOpenDetailModal(false);
          }}
        >
          <Alert severity="info" className={classes.alert}>
            O memorial foi criado e está pronto pare ser compartilhado. O
            responsável deve adicionar um foto de perfil para que ele apareça na
            página inicial do memoriaviva.irmaosvila.com.br.
          </Alert>
          <Typography variant="body2">
            <b>Link do memorial:</b>{" "}
            {`https://memoriaviva.irmaosvila.com.br/perfil/${memorialData?.id}`}
          </Typography>

          <Typography variant="body2">
            <b>Responsável:</b> {memorialData?.relative.name}
          </Typography>

          <Typography variant="body2">
            <b>Email:</b> {memorialData?.relative.email}
          </Typography>
        </CustomModal>
        <div className={classes.form}>
          <div style={{ width: "100%", height: "20px" }}>
            {isRequestLoading && <LinearProgress />}
          </div>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>
              <Typography component="h1" variant="h6">
                Parente responsável
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                name="relativeName"
                variant="outlined"
                fullWidth
                id="relativeName"
                label="Nome completo"
                autoFocus
                value={relativeName}
                onChange={handleChangeRelativeName}
                error={!!relativeNameError}
                helperText={relativeNameError}
                FormHelperTextProps={{
                  classes: {
                    root: helperTextStyles.root,
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                variant="outlined"
                fullWidth
                id="relativeEmail"
                label="Email"
                name="relativeEmail"
                autoComplete="email"
                onChange={handleChangeRelativeEmail}
                value={relativeEmail}
                error={!!relativeEmailError}
                helperText={relativeEmailError}
                FormHelperTextProps={{
                  classes: {
                    root: helperTextStyles.root,
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                variant="outlined"
                fullWidth
                label="Senha"
                name="password"
                autoComplete="password"
                type="password"
                id="password"
                onChange={handleChangePassword}
                value={password}
                error={!!passwordError}
                helperText={passwordError}
                FormHelperTextProps={{
                  classes: {
                    root: helperTextStyles.root,
                  },
                }}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                required
                variant="outlined"
                fullWidth
                label="Confirmar Senha"
                name="confirmPassword"
                autoComplete="password"
                type="password"
                id="confirm-password"
                onChange={handleChangeConfirmPassword}
                value={confirmPassword}
                error={!!confirmPasswordError}
                helperText={confirmPasswordError}
                FormHelperTextProps={{
                  classes: {
                    root: helperTextStyles.root,
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <Divider className={classes.divider} />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>
              <Typography component="h1" variant="h6">
                Ente querido(a)
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12}>
              <FormControl component="fieldset">
                <FormLabel component="legend">Ativar tributo</FormLabel>
                <RadioGroup
                  row
                  aria-label="tribute"
                  name="tribute1"
                  value={isTribute}
                  onChange={handleChangeIsTribute}
                >
                  <FormControlLabel value="s" control={<Radio />} label="Sim" />
                  <FormControlLabel value="n" control={<Radio />} label="Não" />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={8}>
              <TextField
                name="lateName"
                variant="outlined"
                required
                fullWidth
                id="lateName"
                label="Nome completo"
                autoFocus
                value={lateName}
                onChange={handleChangeLateName}
                error={!!lateNameError}
                helperText={lateNameError}
                FormHelperTextProps={{
                  classes: {
                    root: helperTextStyles.root,
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                required
                variant="outlined"
                fullWidth
                id="lateCPF"
                label="CPF"
                name="lateCPF"
                value={lateCPF}
                onChange={handleChangeLateCPF}
                error={!!lateCPFError}
                helperText={lateCPFError}
                FormHelperTextProps={{
                  classes: {
                    root: helperTextStyles.root,
                  },
                }}
                InputProps={{
                  inputComponent: CPFMask as any,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                required
                variant="outlined"
                fullWidth
                id="lateBirthday"
                label="Data de Nascimento"
                name="lateBirthday"
                onChange={handleChangeLateBirthday}
                value={lateBirthday}
                error={!!lateBirthdayError}
                helperText={lateBirthdayError}
                FormHelperTextProps={{
                  classes: {
                    root: helperTextStyles.root,
                  },
                }}
                InputProps={{
                  inputComponent: DateMask as any,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                required
                variant="outlined"
                fullWidth
                id="lateDeathdate"
                label="Data de Falecimento"
                name="lateDeathdate"
                onChange={handleChangeLateDeathday}
                value={lateDeathday}
                error={!!lateDeathdayError}
                helperText={lateDeathdayError}
                FormHelperTextProps={{
                  classes: {
                    root: helperTextStyles.root,
                  },
                }}
                InputProps={{
                  inputComponent: DateMask as any,
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                multiline
                rows={2}
                variant="outlined"
                fullWidth
                name="notes"
                label="Descrição"
                type="notes"
                id="notes"
                onChange={handleChangeNotes}
                value={notes}
                inputProps={{ maxLength: 2000 }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>
              <Divider className={classes.divider} />
            </Grid>
            <Grid item xs={12} sm={12}>
              <Typography component="h1" variant="h6">
                Velório
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                variant="outlined"
                fullWidth
                id="funeralLocation"
                label="Local"
                name="funeralLocation"
                autoComplete="address"
                onChange={handleChangeFuneralLocation}
                value={funeralLocation}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                variant="outlined"
                fullWidth
                id="funeralState"
                label="Estado"
                name="funeralState"
                autoComplete="state"
                onChange={handleChangeFuneralState}
                value={funeralState}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                variant="outlined"
                fullWidth
                id="funeralCity"
                label="Cidade"
                name="funeralCity"
                autoComplete="city"
                onChange={handleChangeFuneralCity}
                value={funeralCity}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <FormControl component="fieldset">
                <FormLabel component="legend">
                  Possui data e horário definido?
                </FormLabel>
                <RadioGroup
                  row
                  aria-label="funeralToBeDefined"
                  name="funeralToBeDefined"
                  value={has_funeral_time}
                  onChange={handleChangeFuneralToBeDefined}
                >
                  <FormControlLabel value={1} control={<Radio />} label="Sim" />
                  <FormControlLabel value={0} control={<Radio />} label="Não" />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                variant="outlined"
                fullWidth
                id="funeralStartDate"
                label="Data de Início"
                name="funeralStartDate"
                onChange={handleChangeFuneralStart}
                value={funeralStart}
                disabled={has_funeral_time === 0}
                InputProps={{
                  inputComponent: DateMask as any,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={2}>
              <TextField
                variant="outlined"
                fullWidth
                id="funeralStartTime"
                label="Hora de Início"
                name="funeralStartTime"
                onChange={handleChangeFuneralStartTime}
                value={funeralStartTime}
                disabled={has_funeral_time === 0}
                InputProps={{
                  inputComponent: HourMask as any,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                variant="outlined"
                fullWidth
                id="funeralEndDate"
                label="Data de Fim"
                name="funeralEndDate"
                onChange={handleChangeFuneralEnd}
                value={funeralEnd}
                disabled={has_funeral_time === 0}
                InputProps={{
                  inputComponent: DateMask as any,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={2}>
              <TextField
                variant="outlined"
                fullWidth
                id="funeralEndTime"
                label="Hora de Fim"
                name="funeralEndTime"
                onChange={handleChangeFuneralEndTime}
                value={funeralEndTime}
                disabled={has_funeral_time === 0}
                InputProps={{
                  inputComponent: HourMask as any,
                }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>
              <Divider className={classes.divider} />
            </Grid>
            <Grid item xs={12} sm={12}>
              <Typography component="h1" variant="h6">
                Sepultamento
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12}>
              <FormControl component="fieldset">
                <FormLabel component="legend">Cremação</FormLabel>
                <RadioGroup
                  row
                  aria-label="gender"
                  name="gender1"
                  value={isCremation}
                  onChange={handleChangeIsCremmation}
                >
                  <FormControlLabel value="s" control={<Radio />} label="Sim" />
                  <FormControlLabel value="n" control={<Radio />} label="Não" />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                variant="outlined"
                fullWidth
                id="burialLocation"
                label="Local"
                name="burialLocation"
                autoComplete="address"
                onChange={handleChangeBurialLocation}
                value={burialLocation}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                variant="outlined"
                fullWidth
                id="burialState"
                label="Estado"
                name="burialState"
                autoComplete="state"
                onChange={handleChangeBurialState}
                value={burialState}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                variant="outlined"
                fullWidth
                id="burialCity"
                label="Cidade"
                name="burialCity"
                autoComplete="city"
                onChange={handleChangeBurialCity}
                value={burialCity}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <FormControl component="fieldset">
                <FormLabel component="legend">
                  Possui data e horário definido?
                </FormLabel>
                <RadioGroup
                  row
                  aria-label="burialToBeDefined"
                  name="burialToBeDefined"
                  value={has_burial_time}
                  onChange={handleChangeBurialToBeDefined}
                >
                  <FormControlLabel value={1} control={<Radio />} label="Sim" />
                  <FormControlLabel value={0} control={<Radio />} label="Não" />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                variant="outlined"
                fullWidth
                id="burialStart"
                label="Data de Início"
                name="burialStart"
                onChange={handleChangeBurialStart}
                value={burialStart}
                disabled={has_burial_time === 0}
                InputProps={{
                  inputComponent: DateMask as any,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={2}>
              <TextField
                variant="outlined"
                fullWidth
                id="burialStart"
                label="Hora de Início"
                name="burialStart"
                onChange={handleChangeBurialStartTime}
                value={burialStartTime}
                disabled={has_burial_time === 0}
                InputProps={{
                  inputComponent: HourMask as any,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                variant="outlined"
                fullWidth
                id="burialEnd"
                label="Data de Fim"
                name="burialEnd"
                onChange={handleChangeBurialEnd}
                value={burialEnd}
                disabled={has_burial_time === 0}
                InputProps={{
                  inputComponent: DateMask as any,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={2}>
              <TextField
                variant="outlined"
                fullWidth
                id="burialStart"
                label="Hora de Fim"
                name="burialStart"
                onChange={handleChangeBurialEndTime}
                value={burialEndTime}
                disabled={has_burial_time === 0}
                InputProps={{
                  inputComponent: HourMask as any,
                }}
              />
            </Grid>
          </Grid>
          <Grid container justify="flex-end">
            <Grid item xs={12} sm={4}>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                onClick={() => validateForm()}
                disabled={isRequestLoading}
              >
                Cadastrar
              </Button>
            </Grid>
          </Grid>
        </div>
      </div>
      <SnackBar
        message={snackMessage}
        isOpen={isSnackOpen}
        severity={isSnackError ? "error" : "success"}
        handleClose={() => setSnackOpen(false)}
      />
    </Container>
  );
}
