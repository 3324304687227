import axios from "axios";
import { Session } from "../../components/NavRoute/NavRoute";
import { UserRequest } from "./CreateUser";

const api = axios.create({
  baseURL: process.env.REACT_APP_SERVER_URL,
});

export const createUser = async (data: UserRequest) => {
  const session = sessionStorage.getItem("iv-crm");
  let token = "";
  if (session !== null) {
    const sessionBody: Session = JSON.parse(session);
    token = sessionBody.accessToken;
  }
  try {
    const response = await api.post("api/auth/signup", data, {
      headers: {
        "x-access-token": token,
      },
    });
    return { res: true, data: response.data };
  } catch (error: any) {
    return { res: false, data: error.response.data };
  }
};
